<template>
  <header class="header">
    <div class="container flex">
      <div class="logo">
        <router-link to="/" class="flex logo__link" :title="$store.state.title">
          <img
            :src="logo"
            :alt="$store.state.title"
            class="logo__img"
            width="58"
            height="49"
          />
          <span class="flex logo__text">
            <span>{{ $store.state.title }} </span>
            <span>{{ $store.state.district }} </span>
            <span>{{ $store.state.slogan }} </span>
            <span></span>
          </span>
        </router-link>
      </div>
      <AppNavbar />
    </div>
  </header>
</template>

<script>
import { defineAsyncComponent } from "vue";
import logo from "./../../assets/nepal-gov.svg";
const AppNavbar = defineAsyncComponent(() => import("../shared/AppNavbar.vue"));
export default {
  name: "AppHeader",
  components: {
    AppNavbar,
  },
  data: function () {
    return {
      logo: logo,
    };
  },
};
</script>

<style lang="scss" scoped>
header {
  padding: 16px 20px;
  .container {
    align-items: center;
    justify-content: space-between;
    max-width: 1240px;
    padding: 0;
  }
}
.logo {
  padding-right: 40px;
  &__link {
    align-items: center;
  }
  &__img {
    margin-right: 6px;
  }
  &__text {
    color: rgba($neutrals-black, 0.5);
    flex-direction: column;
    font-size: 12px;
    font-weight: 700;
    span {
      color: #9e0c18;
      &:first-child {
        font-size: 14px;
      }
    }
  }
}
</style>
